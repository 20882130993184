














































































.calibration-table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;

  th, td {
    padding: 10px;
    border: 1px solid #b4b4b4;
    border-collapse: collapse;
  }

  th {
    background-color: #f1f1f1;
  }
  
  &-cell-highlighted {
    background-color: #ffeaea;
  }

  &-cell-actions-highlighted {
    font-weight: bold;
    background-color: #ffeaea;
  }
}
